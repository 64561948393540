/* eslint-disable */

// obtain plugin
var cc = initCookieConsent();

// run plugin with your configuration
cc.run({
  gui_options: {
    consent_modal: {
      layout: "box", // box/cloud/bar
      position: "bottom left", // bottom/middle/top + left/right/center
    },
  },
  current_lang: "en",
  theme_css:
    "https://res.cloudinary.com/faststartup/raw/upload/v1639186069/landing/cookie-consent_ux52hu.css", // 🚨 replace with a valid path
  autoclear_cookies: true, // default: false
  page_scripts: true, // default: false

  onAccept: function (cookie) {
    // ...
  },

  onChange: function (cookie, changed_preferences) {
    // ...
  },

  languages: {
    en: {
      consent_modal: {
        title: "",
        description:
          ' To provide a more relevant experience for you, we use cookies to enable some website functionality. Cookies help us see which articles most interest you; permit us to deliver content, jobs, and ads tailored to your interests and locations; and provide many other site benefits. For more info, please review our <a href="/privacy" class="cc-link">Privacy Policy</a>.',
        primary_btn: {
          text: "Accept all",
          role: "accept_all", // 'accept_selected' or 'accept_all'
        },
        secondary_btn: {
          text: "Cookies Settings",
          role: "settings", // 'settings' or 'accept_necessary'
        },
      },
      settings_modal: {
        title: "Cookie Preferences Manager",
        save_settings_btn: "Save settings",
        accept_all_btn: "Accept all",
        reject_all_btn: "Reject all",
        close_btn_label: "Close",
        cookie_table_headers: [
          { col1: "Name" },
          { col2: "Domain" },
          { col3: "Expiration" },
          { col4: "Description" },
        ],
        blocks: [
          {
            title: "Your privacy is very important to us.",
            description:
              'Any web site that you visit may store or retrieve personal information, mostly through the use of cookies. The stored or retrieved information might be about you, your preferences or your device and is used for the purposes specified per cookies category below. The data controller of your data processed through our cookies is FastStartup. In addition, some cookies we use are from (and controlled by) third party companies, such as, Google Analytics, Microsoft, Twitter, Youtube, Instagram, and Linkedin Analytics to provide us with web analytics and intelligence about our sites. You can accept the cookies as per your preferences by activating the sliders per cookies category. By accepting cookies, the functionalities described per cookies category will be activated and by not-accepting cookies, such functionalities will not be activated. Because we respect your right to privacy, you can choose not to allow some types of cookies and you have the right to withdraw your consent by adapting your preferences here. Click on the different category headings to find out more and change our default settings. Please read our <a href="/privacy" class="cc-link">Privacy Policy</a> for more information.',
          },
          {
            title: "Strictly necessary cookies",
            description:
              "These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly",
            toggle: {
              value: "necessary",
              enabled: true,
              readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
            },
          },
          {
            title: "First Party Analytics Cookies",
            description: `These cookies allow us to employ data analytics so we can measure and improve the performance of our site and provide more relevant content to you. These cookies don't collect information that identifies a visitor down to an individual level that is available to us. These cookies are not passing personally identifiable information to any external third party other than in limited cases when we engage a service provider to act on our behalf but who is then unable to use the data for their own purposes.`,
            toggle: {
              value: "necessary",
              enabled: true,
              readonly: false, // cookie categories with readonly=true are all treated as "necessary cookies"
            },
          },
          {
            title: "Performance and Functional cookies",
            description: `
                            Performance cookies are generally third-party cookies from vendors we work with or who work on our behalf that collect information about your visit and use of the FastStartup website, for instance which pages you visit the most often, and if you get error messages from web pages. These cookies don't collect information that identifies a visitor. All information these cookies collect is anonymous and is only used to improve how the website works. Third party vendors may have access to this data and may use it to improve their overall services and offerings.</br></br>
                            Functionality cookies allow a site to remember choices you make (such as your user name, language or the region you are in) and provide more enhanced, personal features. These cookies cannot track your browsing activity on other websites. They don’t gather any information about you that could be used for advertising or remembering where you’ve been on the Internet outside our site.`,
            toggle: {
              value: "analytics", // your cookie category
              enabled: true,
              readonly: false,
            },
          },
          {
            title: "Advertisement and Targeting cookies",
            description:
              "Third-party advertising and social media cookies are used to (1) deliver advertisements more relevant to you and your interests; (2) limit the number of times you see an advertisement; (3) help measure the effectiveness of the advertising campaign; and (4) understand people’s behavior after they view an advertisement. They are usually placed on behalf of advertising networks with the site operator’s permission. They remember that you have visited a site and quite often they will be linked to site functionality provided by the other organization. This may impact the content and messages you see on other websites you visit. If you do not allow these cookies you may not be able to use or see these sharing tools or play certain videos on our site.",
            toggle: {
              value: "targeting",
              enabled: true,
              readonly: false,
            },
          },
          {
            title: "More information",
            description:
              'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="mailto:privacy@faststartup.io">contact us</a>.',
          },
        ],
      },
    },
  },
});
